import { subDays, subHours } from 'date-fns';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Container, Divider, Unstable_Grid2 as Grid, LinearProgress, Paper, Skeleton, Stack, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Layout as DashboardLayout } from '@/layouts/dashboard/layout';
import { OverviewBudget } from '@/sections/overview/overview-budget';
import { OverviewLatestOrders } from '@/sections/overview/overview-latest-orders';
import { OverviewLatestProducts } from '@/sections/overview/overview-latest-products';
import { OverviewSales } from '@/sections/overview/overview-sales';
import { OverviewTasksProgress } from '@/sections/overview/overview-tasks-progress';
import { OverviewTotalCustomers } from '@/sections/overview/overview-total-customers';
import { OverviewTotalProfit } from '@/sections/overview/overview-total-profit';
import { OverviewTraffic } from '@/sections/overview/overview-traffic';
import MediaView from '../components/media-view';
import REST from '../utils/rest';
import { useCallback, useEffect, useState } from 'react';
import { toastError } from '../utils/toasts';
import timeRemaining from '../utils/get-time-remaining';
import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import FileManager from '../components/file-manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFileManager } from '../contexts/FileManagerContext';
import { openTest } from '../services/openTest';
import { useAuth } from '../contexts/AuthContext';
import { Scrollbar } from '@/components/scrollbar';
import SimpleBar from 'simplebar-react';
import { datetimeCZ } from '../utils/date-format-cz';
import DateRangePickerEmbeded from '../components/date-range-picker';
import TabLayout from '../components/TabLayout';
import useEnterTestKit from '../hooks/useEnterTest';
import { RequestEmailDialog } from '../components/RequestEmailDialog';
import { RequestLanguageDialog } from '../components/RequestLanguageDialog';

const PastTests = ({ data = [], navigate, t, i18n }) => {
  // if (data?.length === 0) return null;
  return <Grid
    xs={12}
  // md={12}
  // lg={8}
  >
    <Card sx={{ height: '100%' }}>
      <CardHeader title={`${t(`dashboard.ended_tests`)} ${t(`dashboard.in_date_range`)}`} />
      <SimpleBar>
        {/* <Scrollbar sx={{ flexGrow: 1 }}> */}
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t(`general.test`)}
                </TableCell>
                <TableCell>
                  {t(`general.date`)}
                </TableCell>
                <TableCell>
                  {t(`general.state`)}
                </TableCell>
                <TableCell>
                  {t(`general.attempts`)}
                </TableCell>
                <TableCell>
                  {t(`general.best`)} {t(`general.attempt`)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((test, index) =>
                <TableRow
                  hover
                  key={index}
                  style={{ cursor: `pointer` }}
                  onClick={() => navigate(`/test/${test?.ATTEMPT_LAST?.TEST_HISTORY_ID}`)}
                >
                  <TableCell>
                    {test.TEST_KIT_NAME}
                  </TableCell>
                  <TableCell>
                    {datetimeCZ(test.ATTEMPT_LAST?.ATTEMPT_DATE)}
                    {/* {test.ATTEMPT_LAST?.ATTEMPT_DATE ? new Date(test.ATTEMPT_LAST?.ATTEMPT_DATE)?.toLocaleString() : `---`} */}
                  </TableCell>
                  <TableCell>
                    {test.ATTEMPT_LAST?.TEST_HISTORY_STATE_NAME}
                  </TableCell>
                  <TableCell>
                    {test.ATTEMPTS_DONE}
                  </TableCell>
                  <TableCell>
                    {test.SUC_PERCENTAGE_BEST}%
                    <LinearProgress
                      title={`${test.SUC_PERCENTAGE_BEST}%`}
                      value={test.SUC_PERCENTAGE_BEST}
                      variant="determinate"
                      thickness={4}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {data?.length === 0 && <Typography align='center' style={{ width: `100%`, padding: 32 }} >{t(`general.no_records`)}</Typography>}
        </Box>
        {/* </Scrollbar> */}
      </SimpleBar>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        {/* <Button
          color="inherit"
          endIcon={(
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          )}
          size="small"
          variant="text"
        >
          {t(`general.show`)} {t(`general.all`)}
        </Button> */}
      </CardActions>
    </Card>
  </Grid>
}

const TestingHistory = ({ data = [], navigate, t, i18n }) => {
  // if (data?.length === 0) return null;
  return <Grid
    xs={12}
  // md={12}
  // lg={8}
  >
    <Card sx={{ height: '100%' }}>
      <CardHeader title={`${t(`dashboard.ended_attempts`)} ${t(`dashboard.in_date_range`)}`} />
      {/* <Scrollbar sx={{ flexGrow: 1 }}> */}
      <SimpleBar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t(`general.test`)}
                </TableCell>
                <TableCell>
                  {t(`general.date`)}
                </TableCell>
                <TableCell>
                  {t(`general.state`)}
                </TableCell>
                <TableCell>
                  {t(`general.result`)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((attempt, index) =>
                <TableRow
                  hover
                  key={index}
                  style={{ cursor: `pointer` }}
                  onClick={() => navigate(`/test/${attempt.TEST_HISTORY_ID}`)}
                >
                  <TableCell>
                    {attempt.TEST_KIT_NAME}
                  </TableCell>
                  <TableCell>
                    {datetimeCZ(attempt.ATTEMPT_DATE)}
                  </TableCell>
                  <TableCell>
                    {attempt?.TEST_HISTORY_STATE_NAME}
                  </TableCell>
                  <TableCell>
                    {attempt.SUC_PERCENTAGE}%
                    <LinearProgress
                      value={attempt.SUC_PERCENTAGE}
                      variant="determinate"
                      thickness={4}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {data?.length === 0 && <Typography align='center' style={{ width: `100%`, padding: 32 }} >{t(`general.no_records`)}</Typography>}
        </Box>
        {/* </Scrollbar> */}
      </SimpleBar>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        {/* <Button
          color="inherit"
          endIcon={(
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          )}
          size="small"
          variant="text"
        >
          {t(`general.show`)} {t(`general.all`)}
        </Button> */}
      </CardActions>
    </Card>
  </Grid>
}

const now = new Date();

const DateRange = ({ initValue, callback }) => {
  return <DateRangePickerEmbeded callback={callback} />
}

const Page = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation()
  const [data, setData] = useState();
  const { currentUser } = useAuth();
  const [dateRange, setDateRange] = useState([null, null])
  const enterTest = useEnterTestKit();

  const fileManager = useFileManager((result) => console.log(`file manager callback`, result))

  const getData = useCallback(async () => {
    if (dateRange?.[0] && dateRange?.[1]) {
      const params = { from: new Date(dateRange?.[0]).getTime(), to: new Date(dateRange?.[1]).getTime() }
      const queryParams = `?${Object.keys(params)?.map(key => `${key}=${params[key]}`).join(`&`)}`
      console.log({ params, queryParams })
      const [success, newdata] = await REST.GET(`dashboard${queryParams}`)
      if (!success) toastError(`${t(`general.error`)} ${t(`general.loading_data`)}`)
      setData(newdata)
    }

  }, [dateRange])

  useEffect(() => {
    console.log(`date range changed`, dateRange)
    getData()
  }, [dateRange])

  // const getData = useCallback(async () => {
  //   const [success, newdata] = await REST.GET(`dashboard`)
  //   if (!success) toastError()
  //   setData(newdata)
  // }, [])

  // useEffect(() => {
  //   getData()
  // }, [])


  // if (!data)
  //   return <Skeleton />

  const {
    testsCurrent,
    testsPast,
    testingHistory,
  } = data || {};

  const links = currentUser?.menu?.filter(x => [`settings`, `test_evaluation`, `question_evaluation`, `user_evaluation`].includes(x.path))
  const dials = currentUser?.menu?.filter(x => x.dial)

  return <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 4
    }}
  >
    <Container >

      {/* TITLE */}
      <Typography variant="h2" marginBottom={6}>{t(`dashboard.title`)}</Typography>

      <Grid
        container
        // xs={12}
        spacing={3}
        marginBottom={2}
      >

        {/* USER CARD */}
        <Grid xs={12} md={5}>
          <Card sx={{ cursor: `pointer` }} onClick={() => navigate(`account`)}>
            <CardContent style={{ padding: `24px 24px` }}>
              <Stack
                alignItems="flex-start"
                direction="row"
                justifyContent="space-between"
                spacing={3}
              >
                <Stack spacing={1}>
                  <Typography color="text.secondary" variant="overline">{currentUser?.UserEmail}</Typography>
                  <Typography variant="h5" sx={{ marginTop: `0 !important` }}> {currentUser?.UserFullName} </Typography>
                  <Typography color="text.secondary" variant="caption">{currentUser?.UserRoles?.map((item) => t(item))?.join(' | ')}</Typography>
                    <Typography color="text.secondary" variant="caption" style={{ color: 'red' }}>
                    {currentUser?.TempUser ? t(`login_help.temp_acc`) : ""}
                    </Typography>
                </Stack>
                <Stack spacing={1}>
                  <Avatar sx={{ height: 56, width: 56 }} />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>



        {links?.length > 0 && <Grid
          xs={12} md={3}
          display={`flex`}
          flexDirection={`row`}
        >
          <Box>
            <Typography variant="h6" margin={1.8}>{t(`dashboard.links`)}</Typography>
            {links?.map((item, id) => {
              if (item.visible === false) return null;
              return <Button key={id} onClick={() => navigate(`/${item.path}`)}>{item.title}</Button>
            })}
          </Box>
        </Grid>}

        {dials?.length > 0 && <Grid
          xs={12} md={4}
          display={`flex`}
          flexDirection={`row`}
        >
          <Box>
            <Typography variant="h6" margin={1.8}>{t(`dashboard.dials`)}</Typography>
            {dials?.map((item, id) => {
              if (item.visible === false) return null;
              return <Button key={id} onClick={() => navigate(`/${item.path}`)}>{item.title}</Button>
            })}
          </Box>
        </Grid>}

      </Grid>


      <Typography variant="h4" marginBottom={3}>{t(`dashboard.current_tests`)}</Typography>
      <Grid
        container
        // xs={12}
        spacing={3}
      >

        {/* CURRENT TESTS */}
        {/* <CurrentTests xs={12} data={testsCurrent} /> */}
        {/* <Grid
          container
          // xs={12}
          spacing={3}
          marginBottom={5}
        > */}
        {testsCurrent?.map((test, index) =>
          <Grid key={index} xs={12} sm={12} md={6} lg={6} xl={4}>
            <Card sx={{ height: '100%', cursor: `pointer`, backgroundColor: 'primary.light' }}>
              <CardContent style={{ padding: `24px 24px 16px 24px` }}>
                <Stack
                  spacing={2}
                >
                  <Stack spacing={1}>
                    <Typography
                      color="text.secondary"
                      variant="overline"
                    >
                      {timeRemaining(test.VALID_TO, t)}
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        marginTop: `0 !important`,
                        "&:hover": {
                          textDecoration: `underline`
                        }
                      }}>
                      {test.TEST_KIT_NAME}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="caption"
                    >
                      {t(`dashboard.attempts_done`) + ':'} {test.ATTEMPTS_DONE}
                    </Typography>
                    {(test.SUC_PERCENTAGE_LAST_DIFFERENCE > 0 || test.SUC_PERCENTAGE_LAST_DIFFERENCE < 0) && <Stack
                      alignItems="center"
                      direction="row"
                      spacing={2}
                      sx={{ mt: 2 }}
                    >
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                      >
                        <SvgIcon
                          color={test.SUC_PERCENTAGE_LAST_DIFFERENCE > 0 ? 'success' : 'error'}
                          fontSize="small"
                        >
                          {test.SUC_PERCENTAGE_LAST_DIFFERENCE > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />}
                        </SvgIcon>
                        <Typography
                          color={test.SUC_PERCENTAGE_LAST_DIFFERENCE > 0 ? 'success.main' : 'error.main'}
                          variant="body2"
                        >
                          {test.SUC_PERCENTAGE_LAST_DIFFERENCE}%
                        </Typography>
                      </Stack>
                      <Typography
                        color="text.secondary"
                        variant="caption"
                      >
                        {t(`dashboard.since_last_attempt`)}
                      </Typography>
                    </Stack>}
                  </Stack>
                  <Stack
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    width={`100%`}
                  >
                    {/* <Avatar
              sx={{
                // backgroundColor: 'warning.main',
                height: 56,
                width: 56
              }}
            > */}
                    {/* <Paper
                      sx={{
                        display: `flex`,
                        padding: 2,
                        backgroundColor: 'primary.light'
                      }}
                      elevation={0}
                    > */}

                    <LinearProgress
                      color={`success`}
                      style={{ margin: "auto" }}
                      // value={0.8}
                      value={test.SUC_PERCENTAGE_BEST}
                      variant="determinate"
                      thickness={4}
                    // size={44}
                    />
                    {/* </Paper> */}
                    {/* </Avatar> */}
                    <Button
                      color="inherit"
                      endIcon={(
                        <SvgIcon fontSize="small">
                          <ArrowRightIcon />
                        </SvgIcon>
                      )}
                      size="small"
                      variant="text"
                      onClick={() => enterTest(test.TEST_KIT_ID)}
                    >
                      {t(`general.enter`)}
                    </Button>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>)}
        {(!testsCurrent || testsCurrent?.length === 0) &&
          <Grid xs={12} sm={12} md={6} lg={6} xl={4}>
            <Card /* elevation={0} */ /* style={{ border: `1px solid` }} */>
              <CardContent style={{ padding: `32px`, display: `flex` }}>
                <Typography
                  color="text.secondary"
                  variant="overline"
                  margin={`auto`}
                >
                  {t(`dashboard.no_open_tests`)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>}
      </Grid>


      <Typography variant="h4" marginTop={5} marginBottom={3}>{t(`dashboard.history`)}</Typography>

      <Grid
        container
        // xs={12}
        spacing={3}
      >


        <Grid container spacing={3} >
          <Grid>
            <DateRange callback={setDateRange} />
          </Grid>
        </Grid>


        <TabLayout
          tabs={[
            {
              name: `${t(`dashboard.ended_attempts`)}`,
              component: <TestingHistory xs={12} data={testingHistory} navigate={navigate} {...({ t, i18n })} />
            },
            {
              name: `${t(`dashboard.ended_tests`)}`,
              component: <PastTests xs={12} data={testsPast} navigate={navigate} {...({ t, i18n })} />
            },
          ]}
        />

        {/* <PastTests xs={12} data={testsPast} navigate={navigate} {...({ t, i18n })} />
        <TestingHistory xs={12} data={testingHistory} navigate={navigate} {...({ t, i18n })} /> */}



        {/* <Grid xs={12} >
          <MediaView title={`PDF File`} file={REST.URL(`content/test1.pdf`)} />
        </Grid>
        <Grid xs={12} >
          <MediaView title={`Image`} img={REST.URL(`content/capy1.jpeg`)} />
        </Grid>
        <Grid xs={12} >
          <MediaView title={`Video`} video={REST.URL(`content/sample-5s.mp4`)} />
        </Grid> */}

        {/* <Grid xs={12} >
          <FileManager />
        </Grid> */}

        {/* <Button
          color="inherit"
          onClick={() => fileManager?.open({ pickOne: true, callback: (result) => console.log(`file manager callback open()`, result) })}
        >
          File Manager Dialog
        </Button> */}

      </Grid>
    </Container >
    {/* <Placeholder /> */}
  </Box >
}

const Placeholder = () => <>
  <Container maxWidth="xl">
    <Grid
      container
      spacing={3}
    >
      <Grid
        xs={12}
        sm={6}
        lg={3}
      >
        <OverviewBudget
          difference={12}
          positive
          sx={{ height: '100%' }}
          value="$24k"
        />
      </Grid>
      <Grid
        xs={12}
        sm={6}
        lg={3}
      >
        <OverviewTotalCustomers
          difference={16}
          positive={false}
          sx={{ height: '100%' }}
          value="1.6k"
        />
      </Grid>
      <Grid
        xs={12}
        sm={6}
        lg={3}
      >
        <OverviewTasksProgress
          sx={{ height: '100%' }}
          value={75.5}
        />
      </Grid>
      <Grid
        xs={12}
        sm={6}
        lg={3}
      >
        <OverviewTotalProfit
          sx={{ height: '100%' }}
          value="$15k"
        />
      </Grid>
      <Grid
        xs={12}
        lg={8}
      >
        <OverviewSales
          chartSeries={[
            {
              name: 'This year',
              data: [18, 16, 5, 8, 3, 14, 14, 16, 17, 19, 18, 20]
            },
            {
              name: 'Last year',
              data: [12, 11, 4, 6, 2, 9, 9, 10, 11, 12, 13, 13]
            }
          ]}
          sx={{ height: '100%' }}
        />
      </Grid>
      <Grid
        xs={12}
        md={6}
        lg={4}
      >
        <OverviewTraffic
          chartSeries={[63, 15, 22]}
          labels={['Desktop', 'Tablet', 'Phone']}
          sx={{ height: '100%' }}
        />
      </Grid>
      <Grid
        xs={12}
        md={6}
        lg={4}
      >
        <OverviewLatestProducts
          products={[
            {
              id: '5ece2c077e39da27658aa8a9',
              image: '/assets/products/product-1.png',
              name: 'Healthcare Erbology',
              updatedAt: subHours(now, 6).getTime()
            },
            {
              id: '5ece2c0d16f70bff2cf86cd8',
              image: '/assets/products/product-2.png',
              name: 'Makeup Lancome Rouge',
              updatedAt: subDays(subHours(now, 8), 2).getTime()
            },
            {
              id: 'b393ce1b09c1254c3a92c827',
              image: '/assets/products/product-5.png',
              name: 'Skincare Soja CO',
              updatedAt: subDays(subHours(now, 1), 1).getTime()
            },
            {
              id: 'a6ede15670da63f49f752c89',
              image: '/assets/products/product-6.png',
              name: 'Makeup Lipstick',
              updatedAt: subDays(subHours(now, 3), 3).getTime()
            },
            {
              id: 'bcad5524fe3a2f8f8620ceda',
              image: '/assets/products/product-7.png',
              name: 'Healthcare Ritual',
              updatedAt: subDays(subHours(now, 5), 6).getTime()
            }
          ]}
          sx={{ height: '100%' }}
        />
      </Grid>
      <Grid
        xs={12}
        md={12}
        lg={8}
      >
        <OverviewLatestOrders
          orders={[
            {
              id: 'f69f88012978187a6c12897f',
              ref: 'DEV1049',
              amount: 30.5,
              customer: {
                name: 'Ekaterina Tankova'
              },
              createdAt: 1555016400000,
              status: 'pending'
            }
          ]}
          sx={{ height: '100%' }}
        />
      </Grid>
      <Grid
        xs={12}
        md={12}
        lg={8}
      >

      </Grid>
    </Grid>
  </Container>
</>

Page.getLayout = (page) => (
  <DashboardLayout>
    {page}
    <RequestEmailDialog />
    <RequestLanguageDialog />
  </DashboardLayout>
);

export default Page;
