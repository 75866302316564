import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@mui/material';

import PropTypes from 'prop-types';

export const AccountProfile = ({ t, user, login, logout, navigate }) => {

  return <Card>
    <CardContent>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Avatar
          src={user.avatar}
          sx={{
            height: 80,
            mb: 2,
            width: 80
          }}
        />
        <Typography
          gutterBottom
          variant="h5"
        >
          {user.UserFullName}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {user.UserEmail}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {user?.UserRoles?.map((item) => t(item))?.join(' | ')}
        </Typography>
        <Typography color="text.secondary" variant="caption" style={{ color: 'red' }}>
          {user?.TempUser ? t(`login_help.temp_acc`) : ""}
        </Typography>
      </Box>
    </CardContent>
    <Divider />
    <CardActions>
      <Button
        fullWidth
        variant="text"
        onClick={() => logout()}
      >
        {t('general.logout')}
      </Button>
    </CardActions>
  </Card>
}

AccountProfile.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object
}
